var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_desktop) + ')',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"accueil-drawer",attrs:{"rail":_vm.rail,"permanent":""}},[_c('Accueil')],1),_c('v-container',{staticClass:"container-small d-flex flex-column justify-center align-center",staticStyle:{"margin-top":"5%"}},[_c('div',[_c('h1',{staticClass:"text-center mb-2",style:({
          color: _vm.WEBAPPPRO_TEXT_COLOR_4,
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_2,
          fontSize: _vm.WEBAPPPRO_FONT_SIZE_2,
          fontWeight: 700,
          marginTop: '10px',
        })},[_vm._v(" Le QR code est généré ! ")])]),_c('div',{staticStyle:{"margin-top":"10px","margin-bottom":"40px"}},[_c('div',{staticClass:"subtitle-1 text-center",style:({
          fontWeight: 400,
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_2,
          fontSize: _vm.WEBAPPPRO_FONT_SIZE_2,
          lineHeight: '20px',
          textAlign: 'center',
          // color: WEBAPPPRO_TEXT_COLOR_2,
        })},[_c('p',[_vm._v(" Votre candidat peut maintenant le scanner pour accéder "),_c('br'),_vm._v("à l’application de prise de photo d’identité. ")])])]),_c('div',{staticClass:"mx-auto mt-4 mb-4",style:({
        width: '250px',
        height: '250px',
        border: '2px solid ' + _vm.WEBAPPPRO_QRCODE_BORDER_COLOR,
        borderRadius: _vm.WEBAPPPRO_CARD_BORDER_ROUND,
        boxShadow: _vm.WEBAPPPRO_QRCODE_SHADOW,
        padding: '5px',
        backgroundColor: 'white',
      })},[_c('v-img',{attrs:{"src":_vm.qrCodeSrc}})],1),_c('div',{staticClass:"d-flex flex-column align-center justify-center mt-8"},[_c('v-btn',{style:({
          color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_1,
          boxShadow: _vm.WEBAPPPRO_BUTTON_SHADOW_1,
          borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
          textTransform: 'none',
          marginBottom: '10px',
          padding: '0px',
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
          fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
          fontWeight: 'bold',
          lineHeight: '18px',
          letterSpacing: '0em',
          textAlign: 'center',
          width: '240px',
          height: '52px',
        }),attrs:{"color":_vm.WEBAPPPRO_BUTTON_COLOR_1},on:{"click":_vm.newShoot}},[_vm._v("Inscrire un nouveau candidat")]),_c('br'),_c('a',{style:({
          // color: WEBAPPPRO_TEXT_COLOR_2,
          color: '#000000',
          textDecoration: 'underline',
          fontWeight: '600',
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
          fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
          lineHeight: '18px',
          letterSpacing: '0em',
          textAlign: 'center',
        }),attrs:{"href":"#"},on:{"click":_vm.goBackAction}},[_vm._v("Retour à l’accueil")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }