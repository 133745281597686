<template>
  <div
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_desktop) + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
      backgroundSize: 'cover',
    }"
    class="background-container d-flex align-center justify-center flex-column"
  >
    <v-sheet
      class="h-auto d-flex flex-column align-end"
      :style="{ backgroundColor: 'transparent' }"
    >
      <v-sheet
        style="width: 800px; height: 100px"
        class="d-flex flex-column justify-end align-start mb-5"
        :style="{ backgroundColor: 'transparent' }"
      >
        <div style="height: 54px">
          <v-img
            v-if="WEBAPPPRO_LOGO_2"
            class="mb-5 mr-auto"
            :width="200"
            cover
            :src="logoSrc()"
          >
          </v-img>
        </div>
      </v-sheet>
    </v-sheet>
    <v-sheet class="h-auto d-flex" :style="{ backgroundColor: 'transparent' }">
      <v-sheet
        class="d-flex flex-row"
        :style="{ backgroundColor: 'transparent', width: '800px' }"
      >
        <v-sheet
          class="d-flex flex-row justify-center align-center pa-5"
          :style="{
            backgroundColor: WEBAPPPRO_COLOR_1,
            boxShadow: WEBAPPPRO_CARD_SHADOW_1,
            height: '250px',
            width: '515px',
            borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
            border: '1px solid ' + WEBAPPPRO_COLOR_1,
            boxShadow:
              '0 0 5px 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)',
          }"
        >
          <div class="d-flex flex-column pl-5">
            <div
              :style="{
                fontFamily: WEBAPPPRO_FONT_FAMILY_1,
                fontSize: WEBAPPPRO_FONT_SIZE_1,
                fontWeight: 'bold',
                color: WEBAPPPRO_TEXT_COLOR_1,
                'text-transform': 'uppercase',
                display: 'inline-block',
                whiteSpace: 'nowrap',
              }"
            >
              Ma photo d'identité
            </div>
            <div
              :style="{
                fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                fontSize: WEBAPPPRO_FONT_SIZE_3,
                color: WEBAPPPRO_TEXT_COLOR_2,
              }"
            >
              Scanne le QR code pour faire ta photo<br />
              d’identité avec ton smartphone.
            </div>
          </div>
          <div
            :style="{
              backgroundColor: 'transparent',
              height: '148px',
              width: '164px',
            }"
          >
            <v-img
              v-if="WEBAPPPRO_ICON_QRCODE_SCREEN"
              class="mb-5"
              :height="148"
              contain
              :src="imageWallet()"
            >
            </v-img>
          </div>
        </v-sheet>
        <div
          class="mx-auto"
          :style="{
            width: '250px',
            height: '250px',
            border: '2px solid ' + WEBAPPPRO_QRCODE_BORDER_COLOR,
            borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
            boxShadow: WEBAPPPRO_QRCODE_SHADOW,
            padding: '5px',
            backgroundColor: 'white',
          }"
        >
          <v-img :src="qrCodeSrc"></v-img>
        </div>
      </v-sheet>
    </v-sheet>
    <v-sheet class="h-auto" :style="{ backgroundColor: 'red' }"></v-sheet>
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'

export default {
  name: 'ScanQrcode',
  data() {
    return {
      qrCodeSrc:
        'data:image/jpeg;base64,' +
        localStorageService.getValue('qrCodeBase64'),
      background_desktop: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_DESKTOP',
      ),
      WEBAPPPRO_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1',
      ),

      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),

      WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_2',
      ),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      WEBAPPPRO_LOGO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_LOGO_2',
      ),
      WEBAPPPRO_ICON_QRCODE_SCREEN: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_QRCODE_SCREEN',
      ),
      WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_BORDER_ROUND',
      ),
      WEBAPPPRO_QRCODE_BORDER_COLOR: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_QRCODE_BORDER_COLOR',
      ),
      WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      WEBAPPPRO_QRCODE_SHADOW: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_QRCODE_SHADOW',
      ),
      WEBAPPPRO_CARD_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_1',
      ),
      WEBAPPPRO_CARD_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_2',
      ),
      WEBAPPPRO_CARD_SHADOW_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_3',
      ),
    }
  },
  methods: {
    logoSrc() {
      return require(`../assets/images/${this.WEBAPPPRO_LOGO_2}`)
    },
    imageWallet() {
      return require(`../assets/images/${this.WEBAPPPRO_ICON_QRCODE_SCREEN}`)
    },
  },
}
</script>
