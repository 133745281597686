<template>
  <div
    class="d-flex flex-column justify-center align-center"
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_mobile) + ')',
      height: '100vh',
      width: '100vw',
      overflowY: 'hidden',
      backgroundSize: 'cover',
    }"
  >
    <v-alert v-if="error !== ''" type="error" class="mt-2">{{ error }}</v-alert>
    <div v-if="confirm" class="align-items-cente">
      <h1
        class="text-center"
        :style="{
          color: WEBAPPPRO_TEXT_COLOR_1,
          fontSize: WEBAPPPRO_FONT_SIZE_1,
          marginTop: '10px',
          fontFamily: WEBAPPPRO_FONT_FAMILY_1,
          fontWeight: 'regular',
          'text-transform': 'uppercase',
        }"
      >
        Votre commande
      </h1>
      <div
        class="text-center mt-2"
        :style="{
          color: WEBAPPPRO_TEXT_COLOR_2,
          fontSize: WEBAPPPRO_FONT_SIZE_3,
          fontFamily: WEBAPPPRO_FONT_FAMILY_3,
          'font-weight': 'regular',
          marginLeft: '40px',
          width: '240px',
        }"
      >
        <p>
          Merci de renseigner votre email pour recevoir les informations sur
          votre commande
        </p>
      </div>
      <div class="mb-4 mt-4 text-center ml-0">
        <v-text-field
          v-model="email"
          placeholder="Email"
          prepend-inner-icon="mdi-email-outline"
          solo
          dense
          :color="emailColor"
          :borderColor="emailColor"
          @focus="emailColor = 'green'"
          @blur="emailColor = ''"
          :style="{ width: '327px', height: '52px' }"
        ></v-text-field>
      </div>
      <div>
        <v-btn
          :color="WEBAPPPRO_BUTTON_COLOR_1"
          :disabled="uploading"
          @click="verifyEmailAndFetchOrderStatus"
          :loading="uploading"
          :style="{
            color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
            boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
            borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
            'text-transform': 'none',
            marginTop: '10px',
            padding: '25px 100px',
            fontFamily: WEBAPPPRO_FONT_FAMILY_3,
            fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
            'font-weight': 'bold',
            'line-height': '18px',
            'letter-spacing': '0em',
            'text-align': 'center',
            width: '327px',
            height: '52px',
          }"
        >
          Soumettre
        </v-btn>
      </div>
    </div>
    <!--   <div v-if="orderStatus !== null" class="mt-4">
        <p>Statut de la commande: {{ orderStatus }}</p>
      </div>-->

    <div v-if="message">
      <div class="d-flex flex-column">
        <div class="content-container">
          <h1
            class="text-center"
            :style="{
              color: WEBAPPPRO_TEXT_COLOR_1,
              fontSize: WEBAPPPRO_FONT_SIZE_1,
              marginTop: '10px',
              fontFamily: WEBAPPPRO_FONT_FAMILY_1,
              fontWeight: 'regular',
              textTransform: 'uppercase',
            }"
          >
            Merci !
          </h1>
          <v-row align="center" justify="center" class="mt-2">
            <v-col cols="auto">
              <div
                class="text-center"
                :style="{
                  color: WEBAPPPRO_TEXT_COLOR_2,
                  fontSize: WEBAPPPRO_FONT_SIZE_3,
                  fontFamily: WEBAPPPRO_FONT_FAMILY_3,
                  'font-weight': 'regular',
                }"
              >
                <p>{{ orderStatus }}</p>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'
export default {
  name: 'ConfirmationEmail',
  data() {
    return {
      validatedImages: localStorageService.getValue('validatedImages'),
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      timelineColor: localStorageService.getStyle(
        location.hostname,
        'timelineColor',
      ),
      color4: localStorageService.getStyle(location.hostname, 'color4'),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
      ),
      WEBAPPPRO_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1',
      ),
      WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      color2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_2',
      ),
      WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_2',
      ),
      WEBAPPPRO_ICON_COLOR_INFO: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO',
      ),
      WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_2',
      ),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      WEBAPPPRO_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_2',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_FONT_SIZE',
      ),
      WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      items: [],
      background_mobile: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_MOBILE',
      ),
      WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_1',
      ),
      WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_2',
      ),
      WEBAPPPRO_CARD_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_1',
      ),
      WEBAPPPRO_CARD_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_2',
      ),
      WEBAPPPRO_CARD_SHADOW_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_3',
      ),
      WEBAPPPRO_ICON_COLOR_INFO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO_2',
      ),
      WEBAPPPRO_ICON_COLOR_WARNING_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_WARNING_2',
      ),
      choix: [],
      email: '',
      orderStatus: null,
      error: '',
      message: false,
      confirm: true,
      emailColor: '',
    }
  },
  methods: {
    verifyEmailAndFetchOrderStatus() {
      this.error = ''
      this.orderStatus = null
      this.uploading = true // Indique que la vérification est en cours

      // Récupérer l'UID du QR code à partir du localStorage
      const qrcodeUid = localStorageService.getValue('shortliveApiKey')

      // Vérifier que l'e-mail n'est pas vide
      if (!this.email) {
        this.error = 'Veuillez entrer votre adresse e-mail.'
        this.uploading = false
        return
      }

      // Appel à l'API pour vérifier l'e-mail et récupérer le statut de la commande
      requestService
        .post(
          '/service/email_qrcode_check',
          { email: this.email, qrcodeUid: qrcodeUid },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(response => {
          this.uploading = false // Fin de la vérification

          // Succès de la requête
          if (response.data.message) {
            this.orderStatus = response.data.message
            this.message = true
            this.confirm = false
          } else {
            this.error = 'Aucun statut de commande trouvé pour cet e-mail.'
          }
        })
        .catch(error => {
          this.uploading = false // Fin de la vérification en cas d'erreur

          // Capturer le message d'erreur spécifique de la réponse
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            this.error = error.response.data.error
          } else {
            this.error = 'Une erreur est survenue lors de la vérification.'
          }
        })
    },
  },
}
</script>
<style scoped>
.flex-column {
  display: flex;
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
</style>
