var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container d-flex align-center justify-center flex-column",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_desktop) + ')',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"h-auto d-flex flex-column align-end",style:({ backgroundColor: 'transparent' })},[_c('v-sheet',{staticClass:"d-flex flex-column justify-end align-start mb-5",staticStyle:{"width":"800px","height":"100px"},style:({ backgroundColor: 'transparent' })},[_c('div',{staticStyle:{"height":"54px"}},[(_vm.WEBAPPPRO_LOGO_2)?_c('v-img',{staticClass:"mb-5 mr-auto",attrs:{"width":200,"cover":"","src":_vm.logoSrc()}}):_vm._e()],1)])],1),_c('v-sheet',{staticClass:"h-auto d-flex",style:({ backgroundColor: 'transparent' })},[_c('v-sheet',{staticClass:"d-flex flex-row",style:({ backgroundColor: 'transparent', width: '800px' })},[_c('v-sheet',{staticClass:"d-flex flex-row justify-center align-center pa-5",style:({
          backgroundColor: _vm.WEBAPPPRO_COLOR_1,
          boxShadow: _vm.WEBAPPPRO_CARD_SHADOW_1,
          height: '250px',
          width: '515px',
          borderRadius: _vm.WEBAPPPRO_CARD_BORDER_ROUND,
          border: '1px solid ' + _vm.WEBAPPPRO_COLOR_1,
          boxShadow:
            '0 0 5px 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)',
        })},[_c('div',{staticClass:"d-flex flex-column pl-5"},[_c('div',{style:({
              fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_1,
              fontSize: _vm.WEBAPPPRO_FONT_SIZE_1,
              fontWeight: 'bold',
              color: _vm.WEBAPPPRO_TEXT_COLOR_1,
              'text-transform': 'uppercase',
              display: 'inline-block',
              whiteSpace: 'nowrap',
            })},[_vm._v(" Ma photo d'identité ")]),_c('div',{style:({
              fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
              fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
              color: _vm.WEBAPPPRO_TEXT_COLOR_2,
            })},[_vm._v(" Scanne le QR code pour faire ta photo"),_c('br'),_vm._v(" d’identité avec ton smartphone. ")])]),_c('div',{style:({
            backgroundColor: 'transparent',
            height: '148px',
            width: '164px',
          })},[(_vm.WEBAPPPRO_ICON_QRCODE_SCREEN)?_c('v-img',{staticClass:"mb-5",attrs:{"height":148,"contain":"","src":_vm.imageWallet()}}):_vm._e()],1)]),_c('div',{staticClass:"mx-auto",style:({
          width: '250px',
          height: '250px',
          border: '2px solid ' + _vm.WEBAPPPRO_QRCODE_BORDER_COLOR,
          borderRadius: _vm.WEBAPPPRO_CARD_BORDER_ROUND,
          boxShadow: _vm.WEBAPPPRO_QRCODE_SHADOW,
          padding: '5px',
          backgroundColor: 'white',
        })},[_c('v-img',{attrs:{"src":_vm.qrCodeSrc}})],1)],1)],1),_c('v-sheet',{staticClass:"h-auto",style:({ backgroundColor: 'red' })})],1)}
var staticRenderFns = []

export { render, staticRenderFns }