<script>
import { requestService } from '@/services/request.service'
import { isMobile } from 'mobile-device-detect'
import { localStorageService } from '@/services/localStorage.service'

export default {
  name: 'Authentication',
  components: {},
  data() {},
  methods: {
    start() {
      requestService
        .get(
          `/service/authentication_qrcode_check`,
          {},
          {},
          { shortlive_api_key: this.$route.params.id, language: 'fr' },
        )
        .then(data => {
          localStorageService.setObject(
            'WEBAPPPRO_BACKGROUND_DESKTOP',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_BACKGROUND_DESKTOP'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_BACKGROUND_DESKTOP']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_BACKGROUND_MOBILE',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_BACKGROUND_MOBILE'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_BACKGROUND_MOBILE']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_LOGO_2',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_LOGO_2'] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_LOGO_2']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_LOGO_1',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_LOGO_1'] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_LOGO_1']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_ICON_QRCODE_SCREEN',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_ICON_QRCODE_SCREEN'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_ICON_QRCODE_SCREEN']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_ICON_COLOR_INFO',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_ICON_COLOR_INFO'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_ICON_COLOR_INFO']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_ICON_COLOR_WARNING',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_ICON_COLOR_WARNING'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_ICON_COLOR_WARNING']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_1',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_1'] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_1']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_2',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_2'] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_2']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_3',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_3'] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_3']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_4',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_4'] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_4']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_TEXT_COLOR_ETAPES',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_ETAPES'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_ETAPES']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_BUTTON_COLOR_1',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_1'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_1']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_BUTTON_COLOR_2',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_2'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_2']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_TEXT_COLOR_1',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_1'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_1']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_TEXT_COLOR_2',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_2'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_2']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_TEXT_COLOR_3',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_3'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_3']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
            data.data.technicalInformation &&
              data.data.technicalInformation[
                'WEBAPPPRO_BUTTON_TEXT_COLOR_1'
              ] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_BUTTON_TEXT_COLOR_1']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_BUTTON_TEXT_COLOR_2',
            data.data.technicalInformation &&
              data.data.technicalInformation[
                'WEBAPPPRO_BUTTON_TEXT_COLOR_2'
              ] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_BUTTON_TEXT_COLOR_2']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_FONT_FAMILY_1',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY_1'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY_1']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_FONT_FAMILY_2',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY_2'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY_2']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_HAIR',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_HAIR'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_HAIR']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_SKIRT',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_SKIRT'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_SKIRT']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_GIRL_TOP',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_GIRL_TOP'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_GIRL_TOP']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_FONT_FAMILY_3',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY_3'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY_3']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_FONT_SIZE_1',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_1'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_1']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_FONT_SIZE_2',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_2'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_2']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_FONT_SIZE_3',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_3'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_3']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_BUTTON_FONT_SIZE',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_BUTTON_FONT_SIZE'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_BUTTON_FONT_SIZE']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_CARD_BORDER_ROUND',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_CARD_BORDER_ROUND'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_CARD_BORDER_ROUND']
              : null,
          )
          localStorageService.setObject(
            'validatedImages',
            data.data.validatedImages,
          )
          localStorageService.setObject(
            'WEBAPPPRO_BUTTON_BORDER_ROUND',
            data.data.technicalInformation &&
              data.data.technicalInformation[
                'WEBAPPPRO_BUTTON_BORDER_ROUND'
              ] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_BUTTON_BORDER_ROUND']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_QRCODE_BORDER_COLOR',
            data.data.technicalInformation &&
              data.data.technicalInformation[
                'WEBAPPPRO_QRCODE_BORDER_COLOR'
              ] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_QRCODE_BORDER_COLOR']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_BUTTON_SHADOW_1',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_BUTTON_SHADOW_1'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_BUTTON_SHADOW_1']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_BUTTON_SHADOW_2',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_BUTTON_SHADOW_2'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_BUTTON_SHADOW_2']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_QRCODE_SHADOW',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_QRCODE_SHADOW'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_QRCODE_SHADOW']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_CARD_SHADOW_1',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_CARD_SHADOW_1'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_CARD_SHADOW_1']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_CARD_SHADOW_2',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_CARD_SHADOW_2'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_CARD_SHADOW_2']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_CARD_SHADOW_3',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_CARD_SHADOW_3'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_CARD_SHADOW_3']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_ICON_COLOR_INFO_2',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_ICON_COLOR_INFO_2'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_ICON_COLOR_INFO_2']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_ICON_COLOR_WARNING_2',
            data.data.technicalInformation &&
              data.data.technicalInformation[
                'WEBAPPPRO_ICON_COLOR_WARNING_2'
              ] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_ICON_COLOR_WARNING_2']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_CARTE',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_CARTE'] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_CARTE']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_1_FILL_ICON',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_1_FILL_ICON'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_1_FILL_ICON']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_2_FILL_ICON',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_2_FILL_ICON'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_2_FILL_ICON']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT',
            data.data.technicalInformation &&
              data.data.technicalInformation[
                'WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT'
              ] !== undefined
              ? data.data.technicalInformation[
                  'WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT'
                ]
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_FILL_COLOR_FOR_TREATMENT',
            data.data.technicalInformation &&
              data.data.technicalInformation[
                'WEBAPPPRO_FILL_COLOR_FOR_TREATMENT'
              ] !== undefined
              ? data.data.technicalInformation[
                  'WEBAPPPRO_FILL_COLOR_FOR_TREATMENT'
                ]
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT',
            data.data.technicalInformation &&
              data.data.technicalInformation[
                'WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT'
              ] !== undefined
              ? data.data.technicalInformation[
                  'WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT'
                ]
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_CARD_COLOR',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_CARD_COLOR'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_CARD_COLOR']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_CARD_BO_COLOR',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_CARD_BO_COLOR'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_CARD_BO_COLOR']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_DRAWER_COLOR',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_DRAWER_COLOR'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_DRAWER_COLOR']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_INPUT_TEXT_COLOR',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_INPUT_TEXT_COLOR'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_INPUT_TEXT_COLOR']
              : null,
          )

          if (!isMobile) {
            //localStorageService.clearAll()
            localStorageService.setObject(
              'qrCodeBase64',
              data.data.qrCodeBase64,
            )
            this.$router.push({ name: 'ScanQrcode' })
            return
          }

          localStorageService.setObject(
            'shortliveApiKey',
            this.$route.params.id,
          )
          localStorageService.setObject(
            'WEBAPPPRO_INPUT_TEXT_COLOR',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_INPUT_TEXT_COLOR'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_INPUT_TEXT_COLOR']
              : null,
          )
          localStorageService.setObject('priceId', data.data.price.id)
          localStorageService.setObject(
            'addOfferMainId',
            data.data.additional_offer_main.id,
          )
          if (
            data.data.additional_offer !== undefined &&
            data.data.additional_offer !== null
          ) {
            localStorageService.setObject(
              'addOfferId',
              data.data.additional_offer.id,
            )
          } else {
            localStorageService.setObject('addOfferId', null)
          }

          localStorageService.setObject(
            'amount',
            data.data.price.amount_in_local_currency,
          )
          localStorageService.setObject(
            'currency',
            data.data.price.local_currency_symbol,
          )
          localStorageService.setObject(
            'stripePriceId',
            data.data.price.stripe_price_id,
          )
          localStorageService.setObject(
            'documentId',
            data.data.documentId !== null ? data.data.documentId : undefined,
          )
          localStorageService.setObject(
            'payByUser',
            data.data.wallet !== null ? !data.data.wallet : true,
          )
          localStorageService.setObject(
            'photoRejectReasons',
            data.data.photo_reject_reasons !== null
              ? data.data.photo_reject_reasons
              : undefined,
          )
          localStorageService.setObject(
            'rejectStatus',
            data.data.status !== null ? data.data.status : undefined,
          )
          localStorageService.setObject(
            'logo',
            data.data.logo !== null ? data.data.logo : undefined,
          )
          localStorageService.setObject(
            'primaryColor',
            data.data.primaryColor !== null
              ? data.data.primaryColor
              : undefined,
          )
          localStorageService.setObject(
            'secondaryColor',
            data.data.secondaryColor !== null
              ? data.data.secondaryColor
              : undefined,
          )

          localStorageService.setObject(
            'WEBAPPPRO_BACKGROUND_DESKTOP',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_BACKGROUND_DESKTOP'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_BACKGROUND_DESKTOP']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_BACKGROUND_MOBILE',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_BACKGROUND_MOBILE'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_BACKGROUND_MOBILE']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_LOGO_2',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_LOGO_2'] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_LOGO_2']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_LOGO_1',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_LOGO_1'] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_LOGO_1']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_ICON_QRCODE_SCREEN',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_ICON_QRCODE_SCREEN'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_ICON_QRCODE_SCREEN']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_1',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_1'] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_1']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_2',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_2'] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_2']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_3',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_3'] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_3']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_4',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_4'] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_4']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT',
            data.data.technicalInformation &&
              data.data.technicalInformation[
                'WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT'
              ] !== undefined
              ? data.data.technicalInformation[
                  'WEBAPPPRO_TEXT_COLOR_FOR_TREATMENT'
                ]
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_CARD_COLOR',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_CARD_COLOR'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_CARD_COLOR']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_BUTTON_COLOR_1',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_1'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_1']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_TEXT_COLOR_ETAPES',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_ETAPES'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_ETAPES']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_BUTTON_COLOR_2',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_2'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_BUTTON_COLOR_2']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_FONT_FAMILY',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_FONT_FAMILY']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_TEXT_COLOR_3',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_3'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_TEXT_COLOR_3']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_FONT_SIZE_1',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_1'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_1']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_FONT_SIZE_2',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_2'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_2']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_FONT_SIZE_3',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_3'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_FONT_SIZE_3']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_BUTTON_FONT_SIZE',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_BUTTON_FONT_SIZE'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_BUTTON_FONT_SIZE']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_CARD_BORDER_ROUND',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_CARD_BORDER_ROUND'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_CARD_BORDER_ROUND']
              : null,
          )
          localStorageService.setObject(
            'validatedImages',
            data.data.validatedImages,
          )
          localStorageService.setObject(
            'WEBAPPPRO_CARTE',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_CARTE'] !== undefined
              ? data.data.technicalInformation['WEBAPPPRO_CARTE']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_1_FILL_ICON',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_1_FILL_ICON'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_1_FILL_ICON']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_2_FILL_ICON',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_2_FILL_ICON'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_2_FILL_ICON']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_HAIR',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_HAIR'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_HAIR']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_SKIRT',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_SKIRT'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_SKIRT']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_COLOR_GIRL_TOP',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_COLOR_GIRL_TOP'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_COLOR_GIRL_TOP']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_FILL_COLOR_FOR_TREATMENT',
            data.data.technicalInformation &&
              data.data.technicalInformation[
                'WEBAPPPRO_FILL_COLOR_FOR_TREATMENT'
              ] !== undefined
              ? data.data.technicalInformation[
                  'WEBAPPPRO_FILL_COLOR_FOR_TREATMENT'
                ]
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT',
            data.data.technicalInformation &&
              data.data.technicalInformation[
                'WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT'
              ] !== undefined
              ? data.data.technicalInformation[
                  'WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT'
                ]
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_CARD_BO_COLOR',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_CARD_BO_COLOR'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_CARD_BO_COLOR']
              : null,
          )
          localStorageService.setObject(
            'WEBAPPPRO_DRAWER_COLOR',
            data.data.technicalInformation &&
              data.data.technicalInformation['WEBAPPPRO_DRAWER_COLOR'] !==
                undefined
              ? data.data.technicalInformation['WEBAPPPRO_DRAWER_COLOR']
              : null,
          )

          if (
            data.data.status == null ||
            data.data.status == 'pending_payment'
          ) {
            this.$router.push({ name: 'New-order' })
          } else if (data.data.status == 'rejected_photo') {
            this.$router.push({ name: 'WelcomeRedoPhoto' })
          } else if (data.data.status == 'rejected_signature') {
            this.$router.push({ name: 'WelcomeRedoSignature' })
          } else {
            localStorageService.clearAll()
            localStorageService.setObject(
              'shortliveApiKey',
              this.$route.params.id,
            )
            console.log(this.$route.params)
            console.log('hello')
            this.$router.push({ name: 'ConfirmationEmail' })
          }
        })
        .catch(() => {
          localStorageService.clearAll()
          localStorageService.setObject(
            'shortliveApiKey',
            this.$route.params.id,
          )
          console.log(this.$route.params)
          console.log('hello')
          //this.$router.push({ name: 'ConfirmationEmail' })
        })
    },
  },
  mounted() {
    this.start()
  },
}
</script>
